/* eslint-disable import/prefer-default-export */
import React, { FC } from 'react';
import { Box } from '@material-ui/core';
import classNames from 'classnames';
import { IconStarUnfilled, TypographyWrapper } from '../../components';
import {
  TypographyFormat,
  TypographyWeight,
} from '../../components/TypographyWrapper/TypographyWrapper';
import css from './ListingPage.module.css';
import { Listing } from '../../types/sharetribe/listing';
import { convertMoneyToNumber } from '../../util/currency';
import { trackClickLoyaltyPointsSection } from '../../util/heap';
import IconRepresentLoyalty from '../../components/Icons/IconRepresentLoyalty/IconRepresentLoyalty';
import { ShopLoyaltyProgram } from '../../hooks/useShopLoyaltyProgram';

interface SectionListingLoyaltyPointsProps {
  loyaltyPointsConfig: ShopLoyaltyProgram;
  listing: Listing;
  className?: string;
}

interface LoyaltyPointsIconProps {
  iconId: string;
  className?: string;
}

const LoyaltyPointsIcon: FC<LoyaltyPointsIconProps> = (props) => {
  const { iconId, className } = props;
  switch (iconId) {
    case 'represent_prestige':
      return <IconRepresentLoyalty className={className} />;
    default:
      return <IconStarUnfilled className={className} />;
  }
};

export const SectionListingLoyaltyPoints: FC<SectionListingLoyaltyPointsProps> = (props) => {
  const { loyaltyPointsConfig, listing, className } = props;
  const {
    pointsPerMoneyUnit,
    pointsName,
    programName,
    programUrl,
    pdpIconId = '',
  } = loyaltyPointsConfig;
  const { price, publicData } = listing?.attributes || {};

  const potentialLoyaltyPoints = Math.ceil(pointsPerMoneyUnit * convertMoneyToNumber(price));

  const linkUrl = new URL(programUrl);
  linkUrl.searchParams.append('utm_source', 'treet');
  linkUrl.searchParams.append('utm_campaign', 'listing-page');

  const handleLoyaltyPointsClick = () => {
    trackClickLoyaltyPointsSection(
      publicData?.domain,
      publicData?.shopName,
      potentialLoyaltyPoints
    );
    return window.open(linkUrl.href, '_blank');
  };

  return (
    <Box className={classNames(className, css.sectionIso)} onClick={handleLoyaltyPointsClick}>
      <Box display="flex" flexDirection="row" width="100%">
        <Box display="flex" alignItems="center" pr={3}>
          <LoyaltyPointsIcon className={css.sectionIcon} iconId={pdpIconId} />
        </Box>
        {potentialLoyaltyPoints && (
          <Box display="flex" flexDirection="column" mr={2}>
            <TypographyWrapper variant="body2" weight={TypographyWeight.Bold}>
              Earn {potentialLoyaltyPoints.toString()} {pointsName} on this purchase
            </TypographyWrapper>
            <TypographyWrapper variant="body2" format={TypographyFormat.Underlined}>
              Learn more about {programName}
            </TypographyWrapper>
          </Box>
        )}
      </Box>
    </Box>
  );
};
