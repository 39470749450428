import { Box } from '@material-ui/core';
import MuiDivider from '@material-ui/core/Divider';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { IntlShape, useIntl } from 'react-intl';
import { Builder } from '@builder.io/react';
import { useShopCss } from '../../hooks/useShopCss';
import { useIsMobile } from '../../hooks/useIsMobile';
import { useIsShopSideLaunched } from '../../hooks/useIsShopSideLaunched';
import { LISTING_STATE_CLOSED } from '../../util/types';
import { logAddToCart, logFavoriteItem, logUnfavoriteItem } from '../../analytics/ga4analytics';
import { setAuthModalTrigger } from '../../ducks/Auth.duck';
import { addListingToShoppingBag, favoriteListing } from '../../ducks/user.duck';
import { redirectToURLWithModalState } from '../../util/window';
import { ModalParams } from '../../util/urlHelpers';
import { ModalType, setActiveModal } from '../../ducks/modal.duck';
import {
  AuthenticationModal,
  BuilderSection,
  Button,
  FavoriteButton,
  IconComingSoon,
  TypographyWrapper,
} from '../../components';
import { useCurrentListing } from './hooks/useCurrentListing';
import { convertNumberToMoney, formatMoney } from '../../util/currency';
import { useBrandCountryConfig } from '../../hooks/useCountryConfig';
import { richText } from '../../util/richText';
import { useShopConfig } from '../../hooks/shopConfig';
import { Money } from '../../types/sharetribe/money';
import { useAppDispatch } from '../../hooks/appDispatch';
import { useFeatureFlags } from '../../hooks/useFeatureFlags';
import { Feature } from '../../util/featureFlags';
import {
  getAreConsolidatedListingsLoaded,
  getNumConsolidatedListingsShown,
} from './ListingPage.utils';
import SectionConsolidatedListings from './SectionConsolidatedListings/SectionConsolidatedListings';
import SectionDescription from './SectionDescription';
import SectionHeading from './SectionHeading';
import { ITEM_AVAILABILITY_PURCHASED } from '../../util/constants';
import { fetchConsolidatedListings } from './ListingPage.duck';
import { useListingVariantOptionsForDisplay } from '../../hooks/useListingVariantOptions';
import { trackVisitListingPage } from '../../util/heap';
import AppContext from '../../context/AppContext';
import { BuilderSections } from '../../util/builder';
import { useVariantDetails } from './hooks/useVariantDetails';
import css from './ListingPage.module.css';
import { useSellerNotes } from './hooks/useSellerNotes';

const MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE = 16;
const AUTH_MODAL_FAVORITE_TRIGGER = 'auth-modal-favorite-trigger';
const AUTH_MODAL_BUY_TRIGGER = 'auth-modal-buy-trigger';

const AddToBagButton: FC = () => {
  const shopCss = useShopCss();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const isMobile = useIsMobile();
  const isShopSideLaunched = useIsShopSideLaunched();
  const { currentListing: listing, isOwnListing } = useCurrentListing();

  const userState = useSelector<any>((state) => state.user) as any;
  const { currentUser, addToShoppingBagInProgress: isAddToBagInProgress } = userState;

  const shoppingBagListingIds = userState.cartListingIds || [];
  const listingId = listing.id.uuid;
  const isListingInShoppingBag = shoppingBagListingIds.includes(listingId);
  const isCurrentlyClosed = listing.attributes.state === LISTING_STATE_CLOSED;

  const handleAddToShoppingBag = () => {
    logAddToCart(listing);

    if (!currentUser) {
      dispatch(setAuthModalTrigger(AUTH_MODAL_BUY_TRIGGER));
    } else {
      dispatch(addListingToShoppingBag({ listingId, shouldQueueAbandonedBagEmail: true }));

      if (isMobile) {
        redirectToURLWithModalState(history, location, ModalParams.MobileShoppingBag);
      } else {
        dispatch(setActiveModal(ModalType.ShoppingBag));
      }
    }
  };

  let onClick, text;
  if (isOwnListing || isCurrentlyClosed) {
    text = shopCss?.addToBagButton?.addToBagText || 'Add to bag';
  } else if (!isListingInShoppingBag) {
    onClick = handleAddToShoppingBag;
    text = shopCss?.addToBagButton?.addToBagText || 'Add to bag';
  } else {
    text = shopCss?.addToBagButton?.addedToBagText || 'Bagged';
  }

  return (
    <Button
      disabled={!onClick || !isShopSideLaunched}
      inProgress={isAddToBagInProgress}
      className={css.addToBagButton}
      onClick={onClick}
    >
      {!isShopSideLaunched && (
        <IconComingSoon
          className={css.comingSoonImg}
          color={shopCss?.comingSoonBannerColor || 'black'}
          textColor={shopCss?.comingSoonBannerFontColor}
        />
      )}
      {text}
    </Button>
  );
};

const priceData = (price: Money, intl: IntlShape) => {
  if (price) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  }
  return {};
};

interface SectionDetailsProps {
  includeTitleAndVariantDetails?: boolean;
}

const SectionDetails: FC<SectionDetailsProps> = (props) => {
  const { includeTitleAndVariantDetails = true } = props;

  const intl = useIntl();
  const { currentListing, isOwnListing } = useCurrentListing();
  const { currencyConfig } = useBrandCountryConfig();
  const {
    css: brandCss,
    shopName,
    builderConfig,
    shouldDisableConsolidatedListings,
    sizeGuideUrl,
    sizeGuideLabel,
  } = useShopConfig();
  const { treetId } = useContext(AppContext);
  const isMobile = useIsMobile();
  const variantOptions = useListingVariantOptionsForDisplay();
  const variantDetails = useVariantDetails();
  const isConsolidatedListingsEnabled = useFeatureFlags(Feature.ConsolidatedListings);
  const { notes: sellerNotes } = useSellerNotes(currentListing);

  const dispatch = useAppDispatch();
  const userState = useSelector<any>((state) => state.user) as any;
  const { consolidatedListings } = useSelector<any>((state) => state.ListingPage) as any;
  const { authModalTrigger } = useSelector<any>((state) => state.Auth) as any;
  const { currentUser } = userState;

  // We keep track of the favorite button state here so that there's no delay
  // between the user clicking the favorite button and the button's appearance
  // changing in response to the click. Default to false so that we wait until
  // the current user is fetched before setting the actual favorite button status.
  const [isFavoriteButtonSelected, setIsFavoriteButtonSelected] = useState(false);

  const listingId = currentListing.id.uuid;

  const favoriteListingIds = userState.favoritedListingIds;
  useEffect(() => {
    const isFavorited = !!favoriteListingIds?.includes(listingId);
    setIsFavoriteButtonSelected(isFavorited);
  }, [favoriteListingIds]);

  const handleListingChange = async () => {
    let numConsolidatedListings = 0;
    if (isConsolidatedListingsEnabled && !isOwnListing) {
      const isPurchased =
        currentListing.attributes.publicData?.availability === ITEM_AVAILABILITY_PURCHASED;
      const areConsolidatedListingsLoaded = getAreConsolidatedListingsLoaded(
        consolidatedListings,
        currentListing
      );

      // Sold listings should always re-fetch the data, even if the listings are loaded
      const shouldFetchConsolidatedListings = !areConsolidatedListingsLoaded || isPurchased;
      if (shouldFetchConsolidatedListings) {
        const variantOptionsToShow = variantOptions.filter(
          (variantOption: string) => !!currentListing.attributes.publicData[variantOption]
        );

        // If the consolidated listings are not up to date, we will need to fetch them
        const newConsolidatedListings = await dispatch(
          fetchConsolidatedListings(currentListing, variantOptionsToShow)
        );
        // And update the number of consolidated listings that we showed
        numConsolidatedListings = getNumConsolidatedListingsShown(newConsolidatedListings);
      } else {
        // If the consolidated listings are already loaded, no need to dispatch the fetch again
        numConsolidatedListings = getNumConsolidatedListingsShown(consolidatedListings);
      }
    }
    // Always track listing page visit, regardless of consolidated listings
    trackVisitListingPage(currentListing, numConsolidatedListings, treetId, shopName);
  };

  useEffect(() => {
    const currentListingId = currentListing?.id?.uuid;
    if (!currentListingId) {
      return;
    }
    handleListingChange();
  }, [currentListing?.id?.uuid]);

  const handleLoggedOutUserFavoriteClick = () =>
    dispatch(setAuthModalTrigger(AUTH_MODAL_FAVORITE_TRIGGER));

  const handleFavoriteListing = () => {
    setIsFavoriteButtonSelected(!isFavoriteButtonSelected);
    dispatch(favoriteListing({ listingId }));
  };

  const handleFavoriteButtonClick = () => {
    if (isFavoriteButtonSelected) {
      logUnfavoriteItem(currentListing);
    } else {
      logFavoriteItem(currentListing);
    }

    if (!currentUser) {
      handleLoggedOutUserFavoriteClick();
    } else {
      handleFavoriteListing();
    }
  };

  const handleSubmit = () => {
    dispatch(setAuthModalTrigger(null));

    if (authModalTrigger === AUTH_MODAL_FAVORITE_TRIGGER) {
      handleFavoriteListing();
    } else if (authModalTrigger === AUTH_MODAL_BUY_TRIGGER) {
      dispatch(
        addListingToShoppingBag({
          listingId,
          shouldQueueAbandonedBagEmail: true,
        })
      );
    }
  };

  const handleAuthModalClose = () => dispatch(setAuthModalTrigger(null));

  const { price = null, title = '', publicData = {} } = currentListing.attributes;
  const { formattedPrice, priceTitle } = priceData(price, intl);
  const { originalPrice, conditionInfo } = publicData;

  const shouldShowOriginalPrice =
    originalPrice && price?.amount && parseFloat(originalPrice) > price.amount / 100;

  const getFormattedOriginalPrice = () => {
    if (originalPrice && price?.amount) {
      const originalPriceAsMoney = convertNumberToMoney(originalPrice, currencyConfig.currency);
      return priceData(originalPriceAsMoney, intl).formattedPrice;
    }

    return null;
  };

  const formattedOriginalPrice = getFormattedOriginalPrice();
  const maybeFormattedOriginalPrice = shouldShowOriginalPrice ? formattedOriginalPrice : null;

  const richTitle = (
    <span>
      <TypographyWrapper variant="h1">
        {richText(title, {
          longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE,
          longWordClass: css.longWord,
        })}
      </TypographyWrapper>
    </span>
  );

  const listingPageTitleAndVariantDetailsSectionId =
    builderConfig?.sections?.[BuilderSections.ListingPageTitleAndVariantDetailsContent];

  const shouldShowConsolidatedListings =
    isConsolidatedListingsEnabled && !isOwnListing && !shouldDisableConsolidatedListings;
  const areConsolidatedListingsLoaded = getAreConsolidatedListingsLoaded(
    consolidatedListings,
    currentListing
  );

  const authModalBannerText =
    authModalTrigger === AUTH_MODAL_FAVORITE_TRIGGER
      ? 'Sign up or log in to save your favorite items.'
      : 'Please sign up or log in to continue.';

  return (
    <>
      <Box className={css.detailsWrapper}>
        {listingPageTitleAndVariantDetailsSectionId && (
          <BuilderSection
            sectionType={BuilderSections.ListingPageTitleAndVariantDetailsContent}
            sectionId={listingPageTitleAndVariantDetailsSectionId}
            sectionData={{
              title,
              originalPrice: formattedOriginalPrice,
              shouldShowOriginalPrice,
              price: formattedPrice,
              quirks: conditionInfo?.quirks?.join(', '),
              notes: sellerNotes,
              variantDetails,
              sizeGuideLabel,
              sizeGuideUrl,
            }}
          />
        )}
        {includeTitleAndVariantDetails && !listingPageTitleAndVariantDetailsSectionId && (
          <SectionHeading
            priceTitle={priceTitle}
            formattedPrice={formattedPrice}
            formattedOriginalPrice={maybeFormattedOriginalPrice}
            richTitle={richTitle}
          />
        )}
        <Box className={css.buttonsWrapper} bgcolor={brandCss?.backgroundColor || 'white'} mt={2}>
          <AddToBagButton />
          <FavoriteButton
            className={css.favoriteButton}
            iconClassName={css.favoriteIcon}
            isSelected={isFavoriteButtonSelected}
            onClick={handleFavoriteButtonClick}
            hasBorder
          />
        </Box>
        {shouldShowConsolidatedListings && areConsolidatedListingsLoaded && isMobile && (
          <MuiDivider />
        )}
        {shouldShowConsolidatedListings && areConsolidatedListingsLoaded && (
          <SectionConsolidatedListings currentListing={currentListing} />
        )}
        <SectionDescription
          listing={currentListing}
          currentUser={currentUser}
          isOwnListing={isOwnListing}
        />
      </Box>
      <AuthenticationModal
        open={authModalTrigger !== null}
        handleClose={handleAuthModalClose}
        onSuccess={handleSubmit}
        bannerText={authModalBannerText}
      />
    </>
  );
};

Builder.registerComponent(SectionDetails, {
  name: 'SectionDetails',
  inputs: [
    {
      name: 'includeTitleAndVariantDetails',
      type: 'boolean',
    },
  ],
});

export default SectionDetails;
